.team {
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  padding-left: 3vw;
  padding-right: 3vw;
  display: flex;
  justify-content: space-between;
}

.team-bot {
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  padding-left: 3vw;
  display: flex;
  padding-right: 3vw;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/footer-team.svg");
  background-size: cover;
}

.team-left {
  display: flex;
  flex-direction: column;
  flex: 0.5;
}

.team-right {
  align-self: self-end;
  flex: 0.5;
}

.team-heading {
  color: #eaeaea;
  font-size: 120px;
  line-height: 100px;
  font-weight: bold;
}

@media (max-width: 900px) {
  .team,
  .team-bot {
    flex-direction: column !important;
  }

  .team-heading {
    font-size: 50px !important;
    margin-top: 0px !important;
    line-height: 40px !important;
  }

  .team-right {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .team-headingExcerpt {
    margin-top: 10px !important;
  }

  .team-right > img {
    height: 300px !important;
  }
}

.card-team {
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --main-color: #323232;
  --main-focus: #c62368;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.card-team img {
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Center the image horizontally */
  box-shadow: 4px 4px var(--main-color);
  border-radius: 5px;
  border: 2px solid var(--main-color);
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.card-team img:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover */
}
