.contact {
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
  position: relative;
  margin-top: -100px;
  padding-left: 10vw;
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 10vw;
  padding-top: 120px;
}

.contact-heading {
  padding-top: 30px;
  font-size: 85px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
}

@media (max-width: 990px) {
  .contact-heading {
    font-size: 50px !important;
  }
}

@media (max-width: 580px) {
  .contact-heading {
    font-size: 35px !important;
  }
}

.contact-button {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 10px 20px;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #c62368;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 5px 5px 0px #000;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background-color: #fff;
  color: #c62368;
  border: 2px solid #c62368;
  box-shadow: 5px 5px 0px #c62368;
}

.contact-button:active {
  background-color: #fff;
  box-shadow: none;
  transform: translateY(4px);
}

@media (max-width: 900px) {
  .contact {
    padding-left: 6vw !important;
    padding-right: 6vw !important;
  }

  .contact-form {
    margin: 0px !important;
    padding: 5vw !important;
  }

  .contact-formDesc {
    font-size: 18px !important;
  }
}

.contact-formDesc {
  color: #fff;
  font-size: 24px;
  text-align: left;
  font-weight: 500;
}

.contact-form {
  background-color: #1e1e1e;
  margin-left: 10vw;
  margin-right: 10vw;
  padding: 40px;
}

.form-style-1 {
  margin: 10px auto;
}

.form-style-1 li {
  padding: 0;
  display: block;
  list-style: none;
  margin: 10px 0 0 0;
}

.form-style-1 label {
  color: #f3f3f3;
  margin: 0 0 3px 0;
  padding: 0px;
  display: block;
}

.form-style-1 input[type="text"],
.form-style-1 input[type="date"],
.form-style-1 input[type="datetime"],
.form-style-1 input[type="number"],
.form-style-1 input[type="search"],
.form-style-1 input[type="time"],
.form-style-1 input[type="url"],
.form-style-1 input[type="email"],
textarea,
select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 7px;
  margin: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
}

.form-style-1 input[type="text"]:focus,
.form-style-1 input[type="date"]:focus,
.form-style-1 input[type="datetime"]:focus,
.form-style-1 input[type="number"]:focus,
.form-style-1 input[type="search"]:focus,
.form-style-1 input[type="time"]:focus,
.form-style-1 input[type="url"]:focus,
.form-style-1 input[type="email"]:focus,
.form-style-1 textarea:focus,
.form-style-1 select:focus {
  box-shadow: 0 0 8px #88d5e9;
  border: 1px solid #88d5e9;
}

.form-style-1 .field-divided {
  width: 49%;
}

.form-style-1 .field-long {
  width: 100%;
}

.form-style-1 .field-select {
  width: 100%;
}

.form-style-1 .field-textarea {
  height: 100px;
}
.form-style-1 .required {
  color: red;
}
