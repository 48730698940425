.mint-list {
  list-style-image: url(../../assets/icons/bullet.svg);
  list-style-position: inside;
  color: #efefef;
  font-size: 23px;
  line-height: 60px;
  font-weight: 500;
  padding: 10px 0 10px 20px;
  text-indent: -1em;
}

.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint-1 {
  background-repeat: no-repeat;
  /* padding-left: 3vw;
    padding-right: 3vw; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  background-size: cover;
  margin-top: -100px;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
}

.mint-h1 {
  font-size: 150px;
  color: #eaeaea;
  font-family: "CookiesCheese";
  z-index: 1;
  margin-top: -5px;
}

.mint-h2 {
  font-size: 120px;
  color: #c62368;
  margin-top: -120px;
  z-index: 2;
  font-weight: bold;
}

.mint-img1 {
  margin-top: -180px;
  height: 50rem;
}

.mint-itr {
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/mint-bg.svg");
  background-size: cover;

  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  display: flex;
}

@media (max-width: 900px) {
  .mint-h1 {
    font-size: 15vw !important;
  }

  .mint-h2 {
    font-size: 15vw !important;
    margin-top: -12vw !important;
  }

  .mint-list {
    font-size: 16px !important;
    line-height: 35px !important;
  }
}

.mint-button {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 10px 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #c62368;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 5px 5px 0px #000;
  transition: all 0.3s ease;
}

.mint-button:hover {
  background-color: #fff;
  color: #c62368;
  border: 2px solid #c62368;
  box-shadow: 5px 5px 0px #c62368;
}

.mint-button:active {
  background-color: #fff;
  box-shadow: none;
  transform: translateY(4px);
}
