.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-1 {
  background-repeat: no-repeat;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/icons/header.svg");
  background-size: cover;
  margin-top: -100px;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
}

span img {
  float: left;
  width: 40px;
  margin-right: 10px;
}

a:hover span {
  color: #ffffff49;
}

.home-h1 {
  font-size: 150px;
  color: #eaeaea;
  font-family: "CookiesCheese";
  z-index: 1;
  margin-top: -5px;
}

.home-h2 {
  font-size: 120px;
  color: #c62368;
  margin-top: -120px;
  z-index: 2;
  font-weight: bold;
}

.home-img1 {
  margin-top: -130px;
  width: 700px;
  /* height: 50rem; */
}

.home-h3 {
  padding-left: 3vw;
  padding-right: 3vw;
  font-size: 24px;
  color: #eaeaea;
  text-align: center;
  margin-bottom: 30px;
}

.home-gif2 {
  max-width: 2000px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-about {
  max-width: 2000px;
  width: 100%;
  padding-top: 100px;
  padding-left: 3vw;
  padding-right: 3vw;
  background-color: #fa7268;
  color: white;
}

.homeAbout-h1 {
  font-weight: bold;
  font-size: 85px;
  padding-left: 30px;
  margin-top: -220px;
}

.home-about2 {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .home-h1 {
    font-size: 70px;
  }

  .home-h2 {
    font-size: 70px !important;
    margin-top: -65px !important;
  }

  .home-h3 {
    font-size: 3vw !important;
  }

  .homeAbout-h1 {
    font-size: 50px !important;
  }

  .home-about {
    padding-top: 30px !important;
  }

  .home-aboutFollow {
    padding-bottom: 30px !important;
  }

  .home-about2 {
    display: flex !important;
    padding-bottom: 30px !important;
    flex-direction: column !important;
  }

  .home-aboutImage {
    display: none !important;
  }

  .home-vision2 {
    display: flex !important;
    flex-direction: column !important;
    padding-right: 3vw !important;
  }

  .home-visionImage {
    width: 300px !important;
  }

  .home-collections {
    flex-direction: column !important;
  }

  .home-aboutExcerpt {
    font-size: 14px !important;
  }

  .home-visionExcerpt {
    font-size: 14px !important;
  }

  .homeCollectionsExcerpt {
    font-size: 14px !important;
  }

  .home-tokenomicsExcerpt {
    font-size: 14px !important;
  }

  .homeCollections-h1 {
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .home-tokenomicsPie {
    font-size: 20px !important;
    line-height: 18px !important;
  }

  .home-img1 {
    width: 50vw !important;
    margin-top: -12vw !important;
  }
}

.home-aboutFollow {
  flex: 0.2;
}

.home-aboutImage {
  flex: 0.2;
  align-self: self-end;
}

.home-aboutImage > img {
  max-width: none;
  height: auto;
}

.home-aboutExcerpt {
  flex: 0.6;
  font-size: 18px;
}

.home-aboutFollow {
  display: flex;
  flex-direction: column;
}

.home-aboutFollow > span {
  margin-bottom: 10px;
  font-size: 25px;
}

.home-vision {
  padding-top: 50px;
  max-width: 2000px;
  width: 100%;
  padding-left: 3vw;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/ourvision.svg");
  background-size: cover;
  width: 100%;
  color: white;
}

.home-vision2 {
  display: flex;
  justify-content: space-between;
}

.home-visionExcerpt {
  flex: 0.45;
  font-size: 18px;
}

.home-visionCenter {
  flex: 0.15;
}

.home-visionImage {
  flex: 0.4;
  align-self: self-end;
}

.home-tokenomicsTitle {
  max-width: 2000px;
  display: flex;
  width: 100%;
  padding-top: 100px;
  padding-left: 3vw;
  padding-right: 3vw;
}

.home-collections {
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/background2.svg");
  background-size: cover;
  max-width: 2000px;
  display: flex;
  width: 100%;
  padding-top: 100px;
  padding-left: 3vw;
  padding-right: 3vw;
}

.homeCollections-h1 {
  font-weight: bold;
  font-size: 85px;
  line-height: 65px;
  color: #f1f1f1;
}

.homeCollectionsExcerpt {
  font-size: 16px;
}

.home-collections-left {
  flex: 0.44;
  display: flex;
  flex-direction: column;
  color: #f1f1f1;
}

.home-collections-center {
  flex: 0.1;
}

.home-collections-right {
  flex: 0.46;
  display: flex;
  flex-direction: column;
  color: #f1f1f1;
  justify-content: center; /* Center the card vertically */
  align-items: center; /* Center the card horizontally */
}

.btn {
  border: 1px solid #f0484b;
  background-color: #f0484b;
  color: white;
  padding: 15px;
}

.btn:hover {
  border: 1px solid #f0484b;
  background-color: #f0484b00;
  color: #f0484b;
  padding: 15px;
}

.btn1 {
  width: 280px;
  font-size: 12px;
}

.btn2 {
  width: 150px;
  font-size: 14px;
}

.home-tokenomics {
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 5%;
}

.home-tokenomicsPie {
  display: flex;
  font-weight: bold;
  margin-top: 30px;
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #1e1e1e;
}

.home-tokenomicsExcerpt {
  font-size: 16px;
}

.home-tokenomicsPieLeft {
  flex: 0.46;
}

.home-tokenomicsPieCenter {
  flex: 0.08;
}

.home-tokenomicsPieRight {
  flex: 0.46;
}

.home-tokenomicsPieLeft > img {
  margin-top: 30px;
}

.home-tokenomicsPieRight > img {
  margin-top: 30px;
}

.home-button {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #fa7268;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 5px 5px 0px #000;
  transition: all 0.3s ease;
  @media (max-width: 900px) {
    font-size: 12px; /* 70% of 16px */
  }
}

.home-button:hover {
  background-color: #fff;
  color: #fa7268;
  border: 2px solid #fa7268;
  box-shadow: 5px 5px 0px #fa7268;
}

.home-button:active {
  background-color: #fff;
  box-shadow: none;
  transform: translateY(4px);
}

@-webkit-keyframes animate-float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.buttonAboutCollection {
  width: 100%;
  display: inline-block;
  padding: 12px 24px;
  border: 1px solid #efd9ee;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 19px;
  color: #efd9ee;
  z-index: 1;
}

.buttonAboutCollection:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.buttonAboutCollection:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.buttonAboutCollection:hover {
  color: #c62368;
  border: 1px solid #ffffff;
}

.buttonAboutCollection:hover:before {
  top: -35%;
  background-color: #ffffff;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.buttonAboutCollection:hover:after {
  top: -45%;
  background-color: #ffffff;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

/* Media query for mobile view */
@media only screen and (max-width: 768px) {
  .buttonAboutCollection {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

form {
  width: 100%;
  display: block;
}

/* before adding the img to the div with the 
"card-img" class, remove css styles 
.card-img .img::before and .card-img .img::after,
then set the desired styles for .card-img. */
.card {
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --main-color: #323232;
  --main-focus: #c62368;
  width: 80%; /* Increase the width to 80% of parent */
  height: 80%; /* Increase the height to 80% of parent */
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.card img {
  display: block; /* Makes the image a block element */
  height: 310px; /* Set the height */
  margin: 0 auto; /* Center the image horizontally */
  box-shadow: 4px 4px var(--main-color);
  border-radius: 5px;
  border: 2px solid var(--main-color);
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

@media screen and (max-width: 768px) {
  .card img {
    height: 40%; /* Height for mobile */
    margin-bottom: 10px;
  }
}

.card img:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover */
}
.card:last-child {
  justify-content: flex-end;
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--font-color);
}

.card-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-color-sub);
}

.card-divider {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 50px;
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-price {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  color: var(--font-color);
}

.price-label {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.card-price span {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color-sub);
}

.card-btn {
  display: flex; /* Use Flexbox */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Align items horizontally in the center */
  flex-direction: row;
  height: 35px;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  border-radius: 5px;
  padding: 0 15px;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .card-btn {
    font-size: 70%; /* Reduce the font size by 50% */
  }
  .card-btn svg {
    fill: var(--main-color);
    transition: all 0.3s;
    margin-right: 5px; /* Add some space between the icon and the text */
  }
}

.card-btn svg {
  fill: var(--main-color);
  transition: all 0.3s;
  margin-right: 5px; /* Add some space between the icon and the text */
}

.card-img:hover {
  transform: translateY(-3px);
}

.card-btn:hover {
  border: 2px solid var(--main-focus);
}

.card-btn:hover svg {
  fill: var(--main-focus);
}

.card-btn:active {
  transform: translateY(3px);
}
