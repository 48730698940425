.footer {
  padding: 5% 5% 2% 5%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/footer.svg");
  background-size: cover;
  display: flex; /* Added */
  flex-direction: column; /* Added */
  align-items: center; /* Added */
}

.footer-icon {
  margin-left: auto;
  margin-right: auto;
}

.footer-social {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.footer-social a img {
  transition: transform 0.3s ease; /* Smooth transition for enlarging effect */
}

.footer-social a:hover img {
  transform: scale(1.1); /* Enlarge the image by 10% when hovered */
}

.footer-social > img {
  height: 30px;
  color: #fee6d7;
}

.footer-last {
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
  color: white;
}

.footer-h1 {
  font-size: 150px;
  color: #eaeaea;
  font-family: "CookiesCheese";
  z-index: 1;
}

.footer-h2 {
  font-size: 140px;
  color: #c62368;
  margin-top: -120px;
  z-index: 2;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .footer-h1,
  .footer-h2 {
    font-size: 70px;
    margin-top: -65px;
  }
}
