.news {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
  /* background: url("../../assets/icons/what you want");
   background-repeat: no-repeat; */
}

@media (max-width: 990px) {
  .news-heading {
    font-size: 50px !important;
  }

  .news-container {
    grid-template-columns: repeat(auto-fill, 250px) !important;
  }
}

@media (max-width: 580px) {
  .news-heading {
    font-size: 35px !important;
  }

  .news-container {
    grid-template-columns: none !important;
  }
}

.news-container {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.news-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  max-width: 500px;
}

.news-date {
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 20px;
  position: absolute;
  z-index: 100;
  padding: 3px 20px;
  color: #fff;
  background-color: #00000038;
}

.news-item-img {
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.news-item-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.news-heading {
  padding-top: 150px;
  font-size: 85px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
}

.news-item-heading {
  color: #572b25;
  font-size: 18px;
  font-weight: 500;
}

.news-item-desc {
  margin-top: 10px;
  color: #414447;
  font-size: 16px;
}

.news-item-link {
  margin-top: 10px;
  color: #f0484b;
  font-weight: 500;
}
