.footer-team {
  padding: 5% 5% 2% 5%;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/team-footer2.svg");
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  max-width: 2000px;
  display: flex; /* Added */
  flex-direction: column; /* Added */
  align-items: center; /* Added */
}

.footer-icon-team {
  margin-left: auto;
  margin-right: auto;
}

.footer-social-team {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.footer-social-team a img {
  transition: transform 0.3s ease; /* Smooth transition for enlarging effect */
}

.footer-social-team a:hover img {
  transform: scale(1.1); /* Enlarge the image by 10% when hovered */
}

.footer-social-team > img {
  height: 30px;
  color: #fee6d7;
}

.footer-last-team {
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
  color: white;
}
