.footer-contact {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  padding: 5% 5% 2% 5%;
  background-repeat: no-repeat;
  align-items: center;
  background-image: url("../../assets/icons/team-footer2.svg");
  display: flex; /* Added */
  flex-direction: column; /* Added */
  align-items: center; /* Added */
}

.footer-icon-contact {
  margin-left: auto;
  margin-right: auto;
}
.footer-social-contact {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.footer-social-contact a img {
  transition: transform 0.3s ease; /* Smooth transition for enlarging effect */
}

.footer-social-contact a:hover img {
  transform: scale(1.1); /* Enlarge the image by 10% when hovered */
}

.footer-social-contact > img {
  height: 30px;
  color: #fff;
}

.footer-last-contact {
  display: flex;
  justify-content: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
  color: #fff;
}

.footer-contact-h1 {
  font-size: 150px;
  color: #eaeaea;
  font-family: "CookiesCheese";
  z-index: 1;
}

.footer-contact-h2 {
  font-size: 140px;
  color: #c62368;
  margin-top: -120px;
  z-index: 2;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .footer-contact-h1,
  .footer-contact-h2 {
    font-size: 70px;
    margin-top: -65px;
  }
}
