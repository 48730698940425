.press {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
}

.press-exc {
  font-size: 50px;
  display: flex;
  justify-content: center;
}

@media (max-width: 990px) {
  .press-heading {
    font-size: 50px !important;
  }

  .press-container {
    grid-template-columns: repeat(auto-fill, 250px) !important;
  }
}

@media (max-width: 580px) {
  .press-heading {
    font-size: 35px !important;
  }

  .press-item-img {
    height: unset !important;
    width: 40% !important;
    -o-object-fit: unset !important;
    object-fit: unset !important;
  }

  .press-exc {
    font-size: 28px !important;
  }

  .press-container {
    grid-template-columns: none !important;
  }
}

.press-container {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.press-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  max-width: 500px;
}

.press-item-img {
  display: flex;
  height: 100px;
  width: 70%;
  -o-object-fit: contain;
  object-fit: contain;
}

.press-item-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.press-heading {
  padding-top: 150px;
  font-size: 85px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
}

.press-item-desc {
  margin-top: 10px;
  color: #414447;
  font-size: 16px;
}

.press-item-link {
  margin-top: 10px;
  color: #f0484b;
  font-weight: 500;
}
