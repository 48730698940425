.home-timeline {
  padding-top: 200px;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/timeline.svg");
  background-size: cover;  width: 100%;
  max-width: 2000px;
  padding-left: 3vw;
  padding-right: 3vw;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
}

.timeline-container::after {
  background-color: #fee6d7;
  content: "";
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #c62368;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 800px;
  max-width: 70%;
  text-align: right;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  -webkit-animation: float 3s infinite;
  animation: float 3s infinite; /* Apply the floating animation */
}

/* Zoom-in and Shadow Hover Effect */
.timeline-item-content:hover {
  transform: scale(1.05); /* Zoom in */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow */
}

/* Change the circle color on hover */
.timeline-item-content:hover .circle {
  background-color: #3498db; /* Change to the accent color or any other color of your choice */
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }

  .timeline-item-heading {
    font-size: 20px !important;
  }

  .timeline-item-desc {
    font-size: 12px !important;
  }

  .home-timeline-heading {
    font-size: 50px !important;
    
  }
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}

.timeline-item-heading {
    font-size: 1.5em; /* Increase the font size for prominence */
    font-weight: bold; /* Make the heading bold */
    color: #FFD700; /* Change the color to a light gray for better visibility */
    text-transform: uppercase; /* Convert the text to uppercase for a modern look */
    letter-spacing: 1.5px; /* Increase letter spacing for better readability */
    margin-bottom: 10px; /* Add some space between the heading and the description */
    border-bottom: 3px solid #FFD700; /* Add a white bottom border for emphasis */
    padding-bottom: 5px; /* Add some padding to the bottom so the border doesn't touch the text */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.timeline-item-heading:hover {
    border-bottom: 3px solid #00ff78; /* Add a white bottom border for emphasis */
    color: #00ff78; /* Change the color on hover to white */
}

.timeline-item-desc {
  color: white;
  font-size: 16px;
}

.home-timeline-heading {
  padding-left: 30px;
  font-size: 85px;
  color: white;
  font-weight: bold;
}

/* Floating Animation */
@-webkit-keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
